import React, { useEffect } from 'react';
import { Container, Grid, Typography, Paper, CssBaseline, ThemeProvider, styled, Button, ButtonGroup, useMediaQuery, useTheme, Box, CircularProgress } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';
import DriverList from '../components/List'; // Import the new component

const theme = createTheme({
  palette: {
    primary: {
      main: '#e50a0a', // Yellow color
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", sans-serif',
  }
});

const StyledSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  marginBottom: theme.spacing(2),
}));

const ImageColumn = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2), // Add margin to separate rows on mobile
});

const TextColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column', // Display text in a column layout
});

const CustomButton = ({ startIcon, label, link }) => (
  <ThemeProvider theme={theme}>
    <a href={link} target="_blank" rel="noopener noreferrer" className="custom-button-link" style={{ width: '100%', margin: '5px' }}>
      <Button
        variant="contained"
        sx={{
          width: '100%',
          color: 'white',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          paddingLeft: '1.5em',
          mb: { xs: 1, sm: 0 },
          borderRadius: '10px',
          textTransform: 'none',
        }}
        startIcon={startIcon}
      >
        <span style={{ fontFamily: 'roboto' }}>{label}</span>
      </Button>
    </a>
  </ThemeProvider>
);

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Check the user's authentication state
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        // User is not logged in, redirect to login page
        navigate('/login');
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, []);
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [registeredDrivers, setRegisteredDrivers] = React.useState(0);
  const [activatedDrivers, setActivatedDrivers] = React.useState(0);
  const [unactivatedDrivers, setUnactivatedDrivers] = React.useState(0);

  useEffect(() => {
      // Fetch statistics data from the API
      axios.get('https://formsapi.sparkride.sa/api/forms/statistics')
        .then(response => {
          console.log(response.data);
          setRegisteredDrivers(response.data.registeredDrivers);
          setActivatedDrivers(response.data.activatedDrivers);
          setUnactivatedDrivers(response.data.unactivatedDrivers);
        })
        .catch(error => {
          console.error('Error fetching statistics:', error);
        });
    }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Container sx={{mt:10,mb:5}}>
          <Box>
            {/* <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} style={{ padding: '1.5rem' }}>
                  <Typography variant="h6" gutterBottom>
                    Number of Registered Drivers
                  </Typography>
                  <Typography variant="h4">{registeredDrivers}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper elevation={3} style={{ padding: '1.5rem' }}>
                  <Typography variant="h6" gutterBottom>
                    Number of Activated Drivers
                  </Typography>
                  <Typography variant="h4">{activatedDrivers}</Typography>
                </Paper>
              </Grid>
            </Grid> */}

<Grid container spacing={2} sx={{ mt: 5, mb: 5 }} justifyContent="center">
  <Grid item xs={12} md={4}>
    <Paper elevation={3} style={{ padding: '1.5rem', textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom>
        Number of Registered Drivers
      </Typography>
      <Typography variant="h4">{registeredDrivers}</Typography>
    </Paper>
  </Grid>
  <Grid item xs={12} md={4}>
    <Paper elevation={3} style={{ padding: '1.5rem', textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom>
        Number of Activated Drivers
      </Typography>
      <Typography variant="h4">{activatedDrivers}</Typography>
    </Paper>
  </Grid>
  <Grid item xs={12} md={4}>
    <Paper elevation={3} style={{ padding: '1.5rem', textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom>
        Number of Unactivated Drivers
      </Typography>
      <Typography variant="h4">{unactivatedDrivers}</Typography>
    </Paper>
  </Grid>
</Grid>
      <DriverList />
          </Box>
    </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default Home;
