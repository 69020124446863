import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, CssBaseline, ThemeProvider, Container, Menu, MenuItem, Button } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import logoImage from '../assets/images/Black-English-Logo.png'; // Import your logo image
import { Link } from 'react-router-dom'; // Import Link
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Define the theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#e50a0a',
    },
    
  },
});

// Create a styled AppBar
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: '#fff',
  boxShadow: 'none',
}));

// Create a styled Toolbar
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: theme.spacing(1, 0), // Add vertical padding for spacing
  [theme.breakpoints.up('sm')]: {
    marginLeft: '-20px', // Add Left Margin only on screens larger than small
    marginRight: '-20px', // Add Right Margin only on screens larger than small
  },
}));

// Create a styled logo image
const LogoImage = styled('img')({
  height: '40px',
});

// Create a styled language text
const LanguageText = styled('span')({
  cursor: 'pointer',
  color: 'inherit',
  textDecoration: 'none',
});

// Create your Header component
const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState('english');

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (newLanguage) => {
    setCurrentLanguage(newLanguage);
    handleClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledAppBar position="sticky">
        <Container>
          <StyledToolbar>
            <Link to="/">
              <LogoImage src={logoImage} alt="Logo" />
            </Link>
            <Typography variant="h6" component="div" color="#000">
              {/* <LanguageText onClick={handleLanguageClick}>
                {currentLanguage === 'english' ? 'English' : 'Arabic'}
              </LanguageText> */}
              <Button
                variant="outlined"
                sx={{
                  color: "#000",
                  borderColor: "#fff",
                  '&:hover': {
                    fontWeight: 'bold',
                    backgroundColor: 'transparent',
                    color: "#000",
                    borderColor: "#fff",
                  },
                }}
                onClick={() => firebase.auth().signOut()}
              >
                Sign Out
              </Button>
            </Typography>
          </StyledToolbar>
        </Container>
      </StyledAppBar>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleLanguageChange('english')}>English</MenuItem>
        <MenuItem onClick={() => handleLanguageChange('arabic')}>Arabic</MenuItem>
      </Menu>
    </ThemeProvider>
  );
};

export default Header;
