import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  List,
  ListItem,
  Paper,
  Typography,
  Avatar,
  Divider,
  styled,
  Button, 
  Slider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton
} from '@mui/material';
import Papa from 'papaparse';
import DeleteIcon from '@mui/icons-material/Delete';

const BoldDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(1)} 0`,
  borderBottom: `2px solid #000`,
}));

const DriverList = () => {
  const [openModal, setOpenModal] = useState(false);
  const [driverToDelete, setDriverToDelete] = useState(null);

  const handleOpenModal = (id) => {
    setDriverToDelete(id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setDriverToDelete(null);
    setOpenModal(false);
  };

  const [choosenDriver, setChoosenDriver] = useState(null);
  const [openModal2, setOpenModal2] = useState(false);
  const handleOpenModal2 = (driver) => {
    setChoosenDriver(driver);
    setOpenModal2(true);
  };

  const handleCloseModal2 = () => {
    setChoosenDriver(null);
    setOpenModal2(false);
  };

  const [drivers, setDrivers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    // Fetch the list of drivers from the API
    axios.get('https://formsapi.sparkride.sa/api/forms')
      .then(response => {
        setDrivers(response.data);
      })
      .catch(error => {
        console.error('Error fetching drivers:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch the list of drivers from the API
    axios.get('https://formsapi.sparkride.sa/api/forms')
      .then(response => {
        setDrivers(response.data);
      })
      .catch(error => {
        console.error('Error fetching drivers:', error);
      });
  }, [refresh]);

  const getImageUrl = (id, fieldName) => {
    return `https://formsapi.sparkride.sa/api/forms/images/${id}/${fieldName}`;
  };

  const refreshDrivers = () => {
    axios.get('https://formsapi.sparkride.sa/api/forms')

      .then(response => {
        setDrivers(response.data);
        setRefresh(true);
      })
      .catch(error => {
        console.error('Error fetching drivers:', error);
      });
    setRefresh(false);
  };


  const handleDeleteDriver = async (id) => {
    try {
      console.log('Deleting driver with id:', id);
      await axios.delete(`https://formsapi.sparkride.sa/api/forms/${id}`);
      setRefresh(true);
      handleCloseModal();
    } catch (error) {
      console.error('Error deleting driver:', error);
    }
  };



  
  const handleExportCSV = () => {
    const csvData = [];
    
    // Adding CSV headers
    csvData.push([
      'Captain Name', 'Phone Number', 'Marketing Agent Name', 'Marketing Agent Code', 'Registered at'
    ]);
  
    // Adding driver data to CSV
    drivers.forEach(driver => {
      csvData.push([
        driver.name,
        driver.phoneNumber,
        driver.operatorId === 'C1'
          ? 'RAMSHA KHAN'
          : driver.operatorId === 'C2'
          ? 'MOHAMED NADEM'
          : driver.operatorId === 'C3'
          ? 'KHALED RASHEED'
          : 'SPARK RIDE',
        driver.operatorId === 'SparkRide' ? 'ADS' : driver.operatorId,
        new Date(driver.registeredAt).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }),

      ]);
    });
  
    // Convert CSV data to a string
    const csvString = Papa.unparse(csvData);
  
    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  
    // Create a download link and trigger download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', `Drivers Registeration ${new Date().toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })}.csv`);
    link.click();
  };

  return (
    <Paper elevation={3} style={{ padding: '1.5rem' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">List of Drivers</Typography>
        <Button
  variant="outlined"
  sx={{
    color: "#000",
    borderColor: "#fff",
    '&:hover': {
      fontWeight: 'bold',
      backgroundColor: 'transparent',
      color: "#000",
      borderColor: "#fff",
    },
  }}
  onClick={handleExportCSV}
>          Export CSV
        </Button>

       
      </Box>
      <div
        style={{
          height: '400px', // Set the height for the scrollable container
          overflowY: 'scroll', // Enable vertical scrolling
        }}
      >

<List>
  {drivers.map((driver, index) => (
    <React.Fragment key={driver._id}>
      {/* <ListItem
        sx={{
          //backgroundColor: driver.activated ? 'rgba(144, 238, 144, 0.5)' : 'rgba(240, 128, 128, 0.5)',
          display: 'flex',
          alignItems: 'center',
          padding: '1rem',
          cursor: 'pointer',
          borderRadius: '10px', // Add rounded corners
          borderColor: '#000',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Add subtle box shadow
          transition: 'background-color 0.2s ease-in-out', // Add transition effect
          '&:hover': {
            //backgroundColor: driver.activated ? 'rgba(144, 238, 144, 0.8)' : 'rgba(240, 128, 128, 0.8)',
          },
        }}
        // onClick={() => handleOpenModal2(driver)}
      >
        <Avatar
          alt={driver.name}
          src=""
          sx={{ width: 50, height: 50, marginRight: '1rem' }}
        />
        <Box sx={{mr:20}}>
          <Typography variant="h6" sx={{ mr: 2, fontWeight: 'bold' }}>
            {driver.name}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666' }}>
            Phone Number: {driver.phoneNumber}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, mr:20 }}>
          <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666' }}>
            Registered By:
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666' }}>
            {driver.operatorId === 'C1'
              ? 'RAMSHA KHAN - C1'
              : driver.operatorId === 'C2'
              ? 'MOHAMED NADEM - C2'
              : driver.operatorId === 'C3'
              ? 'KHALED RASHEED - C3'
              : 'SPARK RIDE - ADS'}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1}}>
          <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666' }}>
            Registered At:
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666' }}>
            {new Date(driver.registeredAt).toLocaleString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            })}
          </Typography>
        </Box>
      </ListItem> */}

{/* <ListItem
  sx={{
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    cursor: 'pointer',
    borderRadius: '10px',
    borderColor: '#000',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {},
  }}
  // onClick={() => handleOpenModal2(driver)}
>
  <Avatar
    alt={driver.name}
    src=""
    sx={{ width: 50, height: 50, marginRight: '1rem' }}
  />
  <Box sx={{ mr: 20 }}>
    <Typography variant="h6" sx={{ mr: 2, fontWeight: 'bold', textTransform: 'uppercase' }}>
      {driver.name.toUpperCase()}
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666', textTransform: 'uppercase' }}>
      Phone Number: {driver.phoneNumber.toUpperCase()}
    </Typography>
  </Box>
  <Box sx={{ flexGrow: 1, mr: 20 }}>
    <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666', textTransform: 'uppercase' }}>
      Registered By:
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666', textTransform: 'uppercase', width:170 }}>
      {driver.operatorId === 'C1'
        ? 'RAMSHA KHAN - C1'
        : driver.operatorId === 'C2'
        ? 'MOHAMED NADEM - C2'
        : driver.operatorId === 'C3'
        ? 'KHALED RASHEED - C3'
        : 'SPARK RIDE - ADS'}
    </Typography>
  </Box>
  <Box sx={{ flexGrow: 1 }}>
    <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666', textTransform: 'uppercase' }}>
      Registered At:
    </Typography>
    <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666', textTransform: 'uppercase' }}>
      {new Date(driver.registeredAt).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }).toUpperCase()}
    </Typography>
  </Box>
  </ListItem> */}

<ListItem
      key={driver.id}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '1rem',
        cursor: 'pointer',
        borderRadius: '10px',
        borderColor: '#000',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        transition: 'background-color 0.2s ease-in-out',
        '&:hover': {},
      }}
      // onClick={() => handleOpenModal2(driver)}
    >
      <Avatar
        alt={driver.name}
        src=""
        sx={{ width: 50, height: 50, marginRight: '1rem' }}
      />
      <Box sx={{ mr: 20 }}>
        <Typography variant="h6" sx={{ mr: 2, fontWeight: 'bold', textTransform: 'uppercase' }}>
          {driver.name.toUpperCase()}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666', textTransform: 'uppercase' }}>
          Phone Number: {driver.phoneNumber.toUpperCase()}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1, mr: 20 }}>
        <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666', textTransform: 'uppercase' }}>
          Registered By:
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666', textTransform: 'uppercase', width:170 }}>
          {driver.operatorId === 'C1'
            ? 'RAMSHA KHAN - C1'
            : driver.operatorId === 'C2'
            ? 'MOHAMED NADEM - C2'
            : driver.operatorId === 'C3'
            ? 'KHALED RASHEED - C3'
            : 'SPARK RIDE - ADS'}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666', textTransform: 'uppercase' }}>
          Registered At:
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '0.9rem', color: '#666', textTransform: 'uppercase' }}>
          {new Date(driver.registeredAt).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          }).toUpperCase()}
        </Typography>
      </Box>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleOpenModal(driver); // Open the modal for confirmation
        }}
        sx={{ marginLeft: 'auto' }}
      >
        <DeleteIcon />
      </IconButton>
    </ListItem>
    </React.Fragment>
  ))}
</List>

<Dialog open={openModal2} onClose={handleCloseModal2} fullWidth maxWidth="md">
  <DialogTitle>Driver Details</DialogTitle>
  <DialogContent>
    {choosenDriver && (
      <div>
        <Box display="flex" alignItems="center" marginBottom="1rem">
          <Avatar
            alt={choosenDriver.name}
            src={getImageUrl(choosenDriver._id, 'profilePhoto')}
            sx={{ width: 100, height: 100 }}
          />
          <Box marginLeft="1rem">
            <Typography variant="h6">{choosenDriver.name}</Typography>
            <Typography variant="body1">Driver License: {choosenDriver.driverLicense}</Typography>
            <Typography variant="body1">Phone: {choosenDriver.phoneNumber}</Typography>
            <Typography variant="body1">Birth Date: {choosenDriver.dateOfBirth}</Typography>
            <Typography variant="body1">Personal Identity: {choosenDriver.identityNumber}</Typography>
          </Box>
        </Box>
        <Divider />

        <Box marginTop="1rem">
          <Typography variant="h6">Car Information</Typography>
          <Typography variant="body1">Manufacturer: {choosenDriver.carManufacturer}</Typography>
          <Typography variant="body1">Model: {choosenDriver.carModel}</Typography>
          <Typography variant="body1">Production Year: {choosenDriver.carProductionYear}</Typography>
          <Typography variant="body1">No. Passengers: {choosenDriver.numberOfPassengers}</Typography>
          <Typography variant="body1">Color: {choosenDriver.carColor}</Typography>
          <Typography variant="body1">Plate Number: {choosenDriver.plateNumber}</Typography>
          <Typography variant="body1">Vehicle Sequence No.: {choosenDriver.vehicleSequenceNumber}</Typography>
          <Typography variant="body1">
            Car Type: {choosenDriver.carType === 1 ? 'Private Car' : 'Taxi'}
          </Typography>
        </Box>
        <Divider />

        <Box marginTop="1rem">
          <Typography variant="h6">Registration Information</Typography>
          <Typography variant="body1">
            Registered At: {new Date(choosenDriver.registeredAt).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })}
          </Typography>
          <Typography variant="body1">Registered By: {choosenDriver.operatorId}</Typography>
          <Typography variant="body1">
            Activation Status: {choosenDriver.activated ? 'Activated' : 'Not Activated'}
          </Typography>
        </Box>


        <Box marginTop="1rem">
          <Typography variant="h6"  marginBottom="1rem">Documents</Typography>
          <Box display="flex" flexDirection="column">
            <Box marginBottom="0.5rem">
            <Typography variant="h6">Driving License</Typography>
              <img
                src={getImageUrl(choosenDriver._id, 'drivingLicenseFile')}
                alt="Driving License"
                style={{ width: '100%', maxWidth: '200px' }}
              />
              <Box display="flex" justifyContent="center" marginTop="0.5rem">
                <Button
                  variant="outlined"
                  sx={{ color: "#000", borderColor: "#000" , width:'300px'}}
                  download={`driving_license_${choosenDriver.name}.png`}
                  href={getImageUrl(choosenDriver._id, 'drivingLicenseFile')}
                >
                  Download Driving License
                </Button>
              </Box>
            </Box>
            

          </Box>
        </Box>

        <Box marginTop="1rem">
          <Box display="flex" flexDirection="column">
            <Box marginBottom="0.5rem">
            <Typography variant="h6">Peronsal ID </Typography>

              <img
                src={getImageUrl(choosenDriver._id, 'personalIdFile')} // Replace 'image1' with your image field name
                alt="Image 1"
                style={{ width: '100%', maxWidth: '200px' }} // Adjust width as needed
              />
              <Box display="flex" justifyContent="center" marginTop="0.5rem">
                <Button
                  variant="outlined"
                  sx={{ color: "#000", borderColor: "#000" , width:'300px'}}
                  download={`personal_id_${choosenDriver.name}.png`}
                  href={getImageUrl(choosenDriver._id, 'personalIdFile')}
                >
                  Download Personal ID
                </Button>
              </Box>
            </Box>
            

          </Box>
        </Box>

        <Box marginTop="1rem">
          <Box display="flex" flexDirection="column">
            <Box marginBottom="0.5rem">
            <Typography variant="h6">Vehicle License </Typography>

              <img
                src={getImageUrl(choosenDriver._id, 'vehicleLicenseFile')} // Replace 'image1' with your image field name
                alt="Image 1"
                style={{ width: '100%', maxWidth: '200px' }} // Adjust width as needed
              />
              <Box display="flex" justifyContent="center" marginTop="0.5rem">
                <Button
                  variant="outlined"
                  sx={{ color: "#000", borderColor: "#000" , width:'300px'}}
                  download={`vehicle_license_${choosenDriver.name}.png`}
                  href={getImageUrl(choosenDriver._id, 'vehicleLicenseFile')}
                >
                  Download Vehicle License
                </Button>
              </Box>
            </Box>
            

          </Box>
        </Box>

        <Box marginTop="1rem">
          <Box display="flex" flexDirection="column">
            <Box marginBottom="0.5rem">
            <Typography variant="h6">Vehicle Insurance</Typography>

              <img
                src={getImageUrl(choosenDriver._id, 'vehicleInsuranceFile')} // Replace 'image1' with your image field name
                alt="Image 1"
                style={{ width: '100%', maxWidth: '200px' }} // Adjust width as needed
              />
              <Box display="flex" justifyContent="center" marginTop="0.5rem">
                <Button
                  variant="outlined"
                  sx={{ color: "#000", borderColor: "#000" , width:'300px'}}
                  download={`vehicle_insurance_${choosenDriver.name}.png`}
                  href={getImageUrl(choosenDriver._id, 'vehicleInsuranceFile')}
                >
                  Download Vehicle Insurance
                </Button>
              </Box>
            </Box>
            

          </Box>
        </Box>

        <Box marginTop="1rem">
          <Box display="flex" flexDirection="column">
            <Box marginBottom="0.5rem">
            <Typography variant="h6">Vehicle Photo</Typography>

              <img
                src={getImageUrl(choosenDriver._id, 'vehiclePhoto')} // Replace 'image1' with your image field name
                alt="Image 1"
                style={{ width: '100%', maxWidth: '200px' }} // Adjust width as needed
              />
              <Box display="flex" justifyContent="center" marginTop="0.5rem">
                <Button
                  variant="outlined"
                  sx={{ color: "#000", borderColor: "#000" , width:'300px'}}
                  download={`vehicle_photo_${choosenDriver.name}.png`}
                  href={getImageUrl(choosenDriver._id, 'vehiclePhoto')}
                >
                  Download Vehicle Photo
                </Button>
              </Box>
            </Box>
            

          </Box>
        </Box>

       


      </div>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseModal2} color="primary">
      Close
    </Button>
    {/* You can add additional buttons/actions here if needed */}
  </DialogActions>
</Dialog>


      <Dialog open={openModal} onClose={handleCloseModal}>
      <DialogTitle>Confirm Driver Deleteion</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete {driverToDelete?.name}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleDeleteDriver(driverToDelete._id)}
          color="error"
        >
          Confirm Deletion
        </Button>
      </DialogActions>
    </Dialog>
      </div>
    </Paper>
  );
};

export default DriverList;
