import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import logoImage from '../assets/images/Black-English-Logo.png'; // Import your logo image
import { createTheme, styled } from '@mui/material/styles';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


const paperStyles = {
  padding: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const formStyles = {
  width: '100%',
  marginTop: '1rem',
};

const submitStyles = {
  margin: '1.5rem 0',
};

const LogoImage = styled('img')({
  height: '40px',
});

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null); // Add state for login error

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);

      // User is logged in successfully, you can navigate to the dashboard or other pages.
      navigate('/dashboard');
    } catch (error) {
      // Handle login error, display error message to the user.
      console.error('Login error:', error);
      setLoginError('Invalid email or password'); // Set error message

    }
  };

  return (
    <Container component="main" maxWidth="xs" >
      <Paper elevation={3} style={paperStyles} sx={{mt:25}}>
        <LogoImage src={logoImage} alt="Logo" />
        <Typography component="h1" variant="h5" sx={{mt:5}}>
          Login
        </Typography>
        <form style={formStyles} onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {loginError && (
            <Typography color="error" variant="subtitle2" sx={{ marginTop: '8px' }}>
              {loginError}
            </Typography>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={submitStyles}
          >
            Login
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
